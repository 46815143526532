
//--------------------------------------------------

export const ADMINPASSWORD = 'R9f3Zq'

export const frontendDomain = 'https://sitetest.ro'
export const backendDomain = 'https://server.sitetest.ro'

//--------------------------------------------------

export const checkoutText = "Shipping calculated at checkout"

export const contact = frontendDomain + '/home'

export const shipping = 10

let products = [];

export async function loadProducts() {
    try {
        const response = await fetch(backendDomain+'/products');
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        products = await response.json();
    } catch (error) {
        console.error('There has been a problem with your fetch operation:', error);
        throw error;
    }
}

export function getProducts() {
    return products;
}
export const getCartItems = () => {
    return JSON.parse(localStorage.getItem('cartItems')) || [];
};

export const setCartItems = (array) => {
    localStorage.setItem('cartItems', JSON.stringify(array));
};

export const clearCartItems = () => {
    localStorage.setItem('cartItems', JSON.stringify([]));
};
  
export const getQuantitiesFromCartItems = () => {
    const storedItems = localStorage.getItem('cartItems');
    if (storedItems) {
      const cartItems = JSON.parse(storedItems);
      return cartItems.map(item => item.quantity);
    }  
  };

export const sizeOrder = ["XXS", "XS", "S", "M", "L", "XL", "XXL"];



export const LinksPromo = [
    { name: "", link: "https://www.instagram.com/"},
    { name: "", link: "https://www.facebook.com/"}
]
