import React, { useEffect } from 'react';
import ActionBar from '../../actionBar/ActionBar1.js';
import Details from '../../detail/Detailssadas.js';
import Filter from './filter/Filter.js';
import Products from './products/Products2.js';
import './Collection.css';

function Collection() { 
  useEffect(() => {
    document.body.style.overflow = 'hidden';

    return () => {
      document.body.style.overflow = '';
    };
  }, []);

  return (
    <div className="Collection">
      <ActionBar/>
      <Filter/>
      <Products/>
      <div className='CollectionTrue'></div>
      <Details/>
    </div>
  ); 
}

export default Collection;
