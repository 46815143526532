import React, { useState, useEffect } from 'react';
import { backendDomain } from '../GlobalVariables';
import './Detailsasd1.css';

const DetailBar = () => {
  const [logoUrl, setLogoUrl] = useState(null);

  useEffect(() => {
    const formats = ['png'];
    const basePathLogo = backendDomain+'/img/logo/logo';
    
    const loadLogo = async () => {
      for (let format of formats) {
        const url = `${basePathLogo}.${format}`;
        try {
          const response = await fetch(url, { method: 'HEAD' });
          if (response.ok) {
            setLogoUrl(url);
            break;
          }
        } catch (error) {
          console.error(`Failed to load logo: ${url}`, error);
        }
      }
    };

    loadLogo();
  }, []);

  return (
    <div className="detail-bar">
      <div className="detail-content">
        {logoUrl && <img src={logoUrl} alt="Logo" />}
        <div className="vertical-bar"></div>
        <a href='/terms'>TERMS & CONDITIONS</a>
        <div className="vertical-bar"></div>
        <a href='/contact'>CONTACT</a>
      </div>
    </div>
  );
};

export default DetailBar;
