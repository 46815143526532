import React, { useEffect, useState } from 'react';
import ActionBar from '../../../actionBar/ActionBar1.js';
import Details from '../../../detail/Detailssadas.js';
import { backendDomain } from '../../../GlobalVariables.js';
import './Terms.css';

function Terms() {
    const [termsInfo, setTermsInfo] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        fetch(backendDomain+'/termsGet')
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                setTermsInfo(data);
                setLoading(false);
            })
            .catch(err => {
                setError(err.message);
                setLoading(false);
            });
    }, []);
    console.log(termsInfo)
    return (
        <div className='fill'>
            <ActionBar />
            <div className='full'>
                <div className='terms-container'>
                    {termsInfo.map((term, index) => (
                        <div key={index} className='term'>
                            <h2 className='term-title'>{term.title}</h2>
                            <p className='term-paragraph'>{term.text}</p>
                        </div>
                    ))}
                </div>
            </div>
            <Details />
        </div>
    );
}

export default Terms;
