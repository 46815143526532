import React, { useState, useEffect } from 'react';
import './search.css';
import { loadProducts, getProducts, backendDomain } from '../../GlobalVariables.js'; // Update import
const searchIconDark = require('../../img/icons/search_dark.png');

const generateSubstrings = (str) => {
    const substrings = new Set();
    for (let i = 0; i < str.length; i++) {
        for (let j = i + 1; j <= str.length; j++) {
            substrings.add(str.substring(i, j));
        }
    }
    return Array.from(substrings);
};

const editDistance = (str1, str2) => {
    const costs = Array(str2.length + 1).fill(0).map((_, i) => i);
    for (let i = 1; i <= str1.length; i++) {
        let lastValue = i;
        for (let j = 1; j <= str2.length; j++) {
            const newValue = str1[i - 1] === str2[j - 1] ? costs[j - 1] : Math.min(costs[j - 1], Math.min(lastValue, costs[j])) + 1;
            costs[j - 1] = lastValue;
            lastValue = newValue;
        }
        costs[str2.length] = lastValue;
    }
    return costs[str2.length];
};

const similarity = (str1, str2) => {
    const longer = str1.length < str2.length ? str2 : str1;
    const shorter = str1.length < str2.length ? str1 : str2;
    return longer.length === 0 ? 1.0 : (longer.length - editDistance(longer, shorter)) / longer.length;
};

const searchAndReorderItems = (query, items) => {
    const normalizedQuery = query.trim().toLowerCase().replace(/\s+/g, '');
    if (normalizedQuery === '') return { topResults: [], hasResults: false };

    const querySubstrings = generateSubstrings(normalizedQuery);

    const results = items.map(item => {
        const name = item.name.toLowerCase().replace(/\s+/g, '');
        const category = item.category.toLowerCase().replace(/\s+/g, '');

        const maxNameSimilarity = Math.max(...querySubstrings.map(sub => similarity(sub, name)));
        const maxCategorySimilarity = Math.max(...querySubstrings.map(sub => similarity(sub, category)));

        return { item, nameSimilarity: maxNameSimilarity, categorySimilarity: maxCategorySimilarity };
    });

    results.sort((a, b) => b.nameSimilarity - a.nameSimilarity || b.categorySimilarity - a.categorySimilarity);

    const filteredResults = results.filter(result => result.nameSimilarity > 0 || result.categorySimilarity > 0);
    const topResults = filteredResults.slice(0, 3).map(result => result.item);

    return { topResults, hasResults: topResults.length > 0 };
};

const fetchImageUrl = async (name, color) => {
    const formats = ['jpg', 'jpeg', 'png'];
    for (let format of formats) {
        const url = backendDomain+`/img/products/${name}-${color}/0.${format}`;
        try {
            const response = await fetch(url, { method: 'HEAD' });
            if (response.ok) {
                return url;
            }
        } catch (error) {
            console.error(`Failed to load image at ${url}:`, error);
        }
    }
    return null;
};

function Search({ isVisible, onVisibilityChange, setCartVisible }) {
    const [inputValue, setInputValue] = useState('');
    const [topItems, setTopItems] = useState([]);
    const [hasResults, setHasResults] = useState(false);
    const [products, setProducts] = useState([]);
    const [currency, setCurrency] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        // Fetch data from the /currencyGet endpoint
        fetch(backendDomain+'/currencyGet')
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                setCurrency(data);
                setLoading(false);
            })
            .catch(err => {
                setError(err.message);
                setLoading(false);
            });
    }, []);

    useEffect(() => {
        async function fetchData() {
            await loadProducts();
            const productsData = getProducts();
            setProducts(productsData);
        }
        fetchData();
    }, []);

    useEffect(() => {
        if (inputValue.trim()) {
            const { topResults, hasResults } = searchAndReorderItems(inputValue, products);
            setTopItems(topResults);
            setHasResults(hasResults);

            // Fetch images for top results
            topResults.forEach(async (item, index) => {
                const imageUrl = await fetchImageUrl(item.name, item.color);
                if (imageUrl) {
                    setTopItems(prevItems => {
                        const newItems = [...prevItems];
                        newItems[index].image = imageUrl;
                        return newItems;
                    });
                }
            });
        } else {
            setTopItems([]);
            setHasResults(false);
        }
    }, [inputValue, products]);

    const handleButtonClick = () => {
        setCartVisible(false);
        onVisibilityChange(false);
    };

    const handleInputChange = (e) => setInputValue(e.target.value);

    const handleClearInput = () => setInputValue('');

    const showResults = hasResults && topItems.length > 0;
    const showNoResults = !showResults && inputValue.trim();

    return (
        <div className="search-background" style={{ display: isVisible ? 'block' : 'none' }}>
            <div className="search">
                <button className='x' onClick={handleButtonClick}>&#10005;</button>
                <div className='searchZone'>
                    <img src={searchIconDark} alt="Search Icon" />
                    <input
                        value={inputValue}
                        onChange={handleInputChange}
                        placeholder="Search"
                    />
                    {inputValue && (
                        <button className='x2' onClick={handleClearInput}>&#10005;</button>
                    )}
                    {showResults && (
                        <div className='PRODUCTS'>
                            <p>PRODUCTS</p>
                            <div className='itemsGrid2'>
                                {topItems.map((item, index) => (
                                    <div className='items2' key={index}>
                                        <a href={`/${item.name.toLowerCase()}-${item.category.toLowerCase()}-${item.size.toLowerCase()}-${item.color.toLowerCase()}`}>
                                            <img className='ItemImg2' src={item.image} alt="Item" />
                                            <div className='ItemName2'>
                                                <h1 className='a2'>{item.name} | {item.category}</h1>
                                                <h1>{item.price.toFixed(2)} {currency}</h1>
                                                <div className='b2' />
                                                <p>Color: {item.color}</p>
                                                <p>Size: {item.size}</p>
                                            </div>
                                        </a>
                                    </div>
                                ))}
                            </div>
                            <a className='collectioSEE' href='/collection'>SEE MORE . . .</a>
                        </div>
                    )}
                    {showNoResults && (
                        <div className='no-results'>
                            <p>No results found for "{inputValue}"</p>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}

export default Search;
