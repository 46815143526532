import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import ActionBar from '../../actionBar/ActionBar1.js';
import Details from '../../detail/Detailssadas.js';
import './Product.css';
import { getCartItems, setCartItems, loadProducts, getProducts, backendDomain } from '../../GlobalVariables.js';

function link(inputString) {
  const cleanedString = inputString.replace(/\//g, '');
  return cleanedString.split('-');
}

function matchItems(inputArray, items) {
  const [name, category, size, color] = inputArray.map(value => value.toLowerCase());

  return items.findIndex(item => 
    item.name.toLowerCase() === name &&
    item.category.toLowerCase() === category &&
    item.size.toLowerCase() === size &&
    item.color.toLowerCase() === color
  );
}

function createFilteredArray(splitArray, items) {
  const [name, category] = splitArray.map(value => value.toLowerCase());

  return items.filter(item => 
    item.name.toLowerCase() === name &&
    item.category.toLowerCase() === category
  );
}

const fetchFirstImage = async (name, color) => {
  const url = `${backendDomain}/img/products/${name}-${color}/0.png`;
  const response = await fetch(url);

  if (response.ok) {
      return url; // Returnează URL-ul primei imagini găsite
  } else {
      return null; // Sau poți returna altceva dacă imaginea nu există
  }
};


const addToCart = (product, cartQuantity, setCartQuantity, productImages) => {
  const cartItems = getCartItems();
  const existingProductIndex = cartItems.findIndex(item => 
    item.name === product.name &&
    item.category === product.category &&
    item.color === product.color &&
    item.size === product.size
  );

  if (existingProductIndex !== -1) {
    
    if (cartItems[existingProductIndex].quantity < product.quantity) {
      cartItems[existingProductIndex].quantity += 1;
      setCartQuantity(cartItems[existingProductIndex].quantity);
    }
  } else {
    
    const newData = {
      name: product.name,
      category: product.category,
      price: product.price,
      color: product.color,
      size: product.size,
      image: fetchFirstImage,
      quantity: 1,
      quantityMax: product.quantity,
    };
    
    cartItems.push(newData);
    setCartQuantity(1);
  }

  setCartItems(cartItems);
  window.location.reload()
};



function Product() {
  const [products, setProducts] = useState([]);
  const [currency, setCurrency] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [productImages, setProductImages] = useState([]);
  const [cartQuantity, setCartQuantity] = useState(0);

  const location = useLocation();

  useEffect(() => {
    fetch(backendDomain+'/currencyGet')
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => {
        setCurrency(data);
        setLoading(false);
      })
      .catch(err => {
        setError(err.message);
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    async function initializeApp() {
      await loadProducts();
      const productsData = getProducts();
      setProducts(productsData);

      const currentUrl = location.pathname;
      const splitArray = link(currentUrl);
      const index = matchItems(splitArray, productsData);

      if (index !== -1) {
        const product = productsData[index];
        const cartItems = getCartItems();
        const existingProductIndex = cartItems.findIndex(item => 
          item.name === product.name &&
          item.category === product.category &&
          item.color === product.color &&
          item.size === product.size
        );
        if (existingProductIndex !== -1) {
          setCartQuantity(cartItems[existingProductIndex].quantity);
        } else {
          setCartQuantity(0);
        }

        const images = await fetchAllImages(product.name, product.color);
        setProductImages(images);
      }
    }
    initializeApp();
  }, [location.pathname]);

  const fetchAllImages = async (name, color) => {
    const imageUrls = [];
    let index = 0;
    let moreImages = true;

    while (moreImages) {
        const url = `${backendDomain}/img/products/${name}-${color}/${index}.png`;
        const response = await fetch(url);

        if (response.ok) {
            imageUrls.push(url);
            index++;

            // Verifică dacă următorul URL ar fi valid
            const nextUrl = `${backendDomain}/img/products/${name}-${color}/${index}.png`;
            const nextResponse = await fetch(nextUrl);
            if (!nextResponse.ok) {
                moreImages = false; // Oprește bucla dacă următoarea imagine nu există
            }
        } else {
            moreImages = false;
        }
    }

    return imageUrls;
};


  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  const currentUrl = location.pathname;
  const splitArray = link(currentUrl);
  const index = matchItems(splitArray, products);
  const product = products[index];
  const filteredArray = createFilteredArray(splitArray, products);

  if (!product) return null;

  const uniqueSizeColorMap = new Map();
  filteredArray.forEach(item => {
    const key = `${item.size}-${item.color}`;
    if (!uniqueSizeColorMap.has(key)) {
      uniqueSizeColorMap.set(key, item);
    }
  });

  const uniqueSizeColorArray = Array.from(uniqueSizeColorMap.values());
  const availableSizes = [...new Set(uniqueSizeColorArray.map(item => item.size))];
  const availableColors = [...new Set(
    uniqueSizeColorArray
      .filter(item => availableSizes.includes(item.size))
      .map(item => item.color)
  )];

  return (
    <div>
      <ActionBar />
      <div className='fullProduct'>
          <div className='Product'>
            <div className='scroll'>
              <div className='productImage'>
                {productImages.length > 0 ? (
                  productImages.map((imageUrl, index) => (
                    <img src={imageUrl} alt={`Product ${index}`} key={index} />
                  ))
                ) : (
                  <div>No images available</div>
                )}
              </div>
              <div className='productText'>
                <h2 style={{ color: 'gray' }}>{product.color.toUpperCase()}</h2>
                <h1>{product.name.toUpperCase()}</h1>
                <h2>{product.price.toFixed(2)} {currency}</h2>
                <h2 style={{ color: 'gray', fontWeight: 'lighter' }}>{product.description.toUpperCase()}</h2>
                <h2 style={{ fontWeight: 'lighter' }}>SIZE: {product.size.toUpperCase()}</h2>

                <div className='sizeSelect'>
                  <h2>COLORS</h2>
                  <div className='colorButtons'>
                    {availableColors.map((color, index) => {
                      const hasSize = uniqueSizeColorArray.some(item => item.color === color && item.size === product.size);
                      if (!hasSize) return null;

                      const isSelected = color.toLowerCase() === product.color.toLowerCase();
                      const borderColor = isSelected ? { border: "3px solid #4d4d4d" } : {};
                      return (
                        <a href={`/${product.name.toLowerCase()}-${product.category.toLowerCase()}-${product.size.toLowerCase()}-${color.toLowerCase()}`} key={index}>
                          <div style={borderColor}>
                            <div className="colorSquare" style={{ backgroundColor: products.find(item => item.color.toLowerCase() === color.toLowerCase())?.colorview }}></div>
                            {color.toUpperCase()}
                          </div>
                        </a>
                      );
                    })}
                  </div>
                </div>

                <div className='colorsSelect'>
                  <h2>SIZE</h2>
                  <div className='sizeButtons'>
                    {availableSizes.map((size, index) => {
                      const hasColor = uniqueSizeColorArray.some(item => item.size === size && item.color === product.color);
                      if (!hasColor) return null;

                      const isSelected = size.toLowerCase() === product.size.toLowerCase();
                      const borderColor = isSelected ? { border: "3px solid #4d4d4d" } : {};
                      return (
                        <a href={`/${product.name.toLowerCase()}-${product.category.toLowerCase()}-${size.toLowerCase()}-${product.color.toLowerCase()}`} key={index}>
                          <div style={borderColor}>{size.toUpperCase()}</div>
                        </a>
                      );
                    })}
                  </div>
                </div>

                <button 
                  className='addToCard' 
                  onClick={() => addToCart(product, cartQuantity, setCartQuantity)}
                  disabled={cartQuantity >= product.quantity}
                >
                  ADD TO CARD
                </button>
                {cartQuantity >= product.quantity && (
                  <p style={{ color: 'red' }}>No more items available in stock.</p>
                )}
              </div>
            </div>
          </div>
      </div>
      <Details />
    </div>
  );
}

export default Product;
