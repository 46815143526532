import React, { useState, useEffect, useRef } from 'react';
import './Slider.css';
import { loadProducts, getProducts, backendDomain } from '../../../GlobalVariables';

function Collection() {
  const [products, setProducts] = useState([]);
  const [categories, setCategories] = useState([]);
  
  useEffect(() => {
    async function initializeApp() {
      await loadProducts();
      const productsData = getProducts();
      setProducts(productsData);
    }
    initializeApp();
  }, []);
  
  useEffect(() => {
    const uniqueCategories = [...new Set(products.map(product => product.category))];
    setCategories(uniqueCategories);
  }, [products]);

  const buildImageUrls = (category) => {
    const formats = ['png']
    return formats.map(format => backendDomain+`/img/backgroundCollectionPage/${category}.${format}`);
  };

  const obiecteArray = categories.map((category, index) => {
    const imageUrls = buildImageUrls(category);
    return {
      category: category,
      imageUrls: imageUrls,
    };
  });

  const [currentIndex, setCurrentIndex] = useState(0);
  const slidesToShow = 3;
  const totalSlides = Math.ceil(obiecteArray.length / slidesToShow);
  const sliderRef = useRef(null);
  const progressRef = useRef(null);
  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);

  useEffect(() => {
    const slider = sliderRef.current;
    if (slider && progressRef.current) {
      const updateProgress = () => {
        const progressWidth = ((currentIndex + 1) / totalSlides) * 100;
        progressRef.current.style.width = `${progressWidth}%`;
      };
      
      const resizeObserver = new ResizeObserver(() => {
        updateProgress();
      });
      resizeObserver.observe(slider);

      return () => resizeObserver.disconnect();
    }
  }, [currentIndex, totalSlides]);

  useEffect(() => {
    const slider = sliderRef.current;
    if (slider) {
      const slideWidth = slider.offsetWidth / slidesToShow;
      slider.scrollLeft = currentIndex * slideWidth;
    }
  }, [currentIndex, slidesToShow]);

  const nextSlide = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === totalSlides - 1 ? 0 : prevIndex + 1
    );
  };

  const prevSlide = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? totalSlides - 1 : prevIndex - 1
    );
  };

  const handleStart = (e) => {
    setIsDragging(true);
    const clientX = e.type === 'mousedown' ? e.pageX : e.touches[0].clientX;
    setStartX(clientX - sliderRef.current.getBoundingClientRect().left);
    setScrollLeft(sliderRef.current.scrollLeft);
  };

  const handleMove = (e) => {
    if (!isDragging) return;
    e.preventDefault();
    const clientX = e.type === 'mousemove' ? e.pageX : e.touches[0].clientX;
    const walk = (clientX - startX) * 2;
    sliderRef.current.scrollLeft = scrollLeft - walk;
  };

  const handleEnd = () => {
    setIsDragging(false);
    updateCurrentIndex();
  };

  const updateCurrentIndex = () => {
    const slider = sliderRef.current;
    const slideWidth = slider.offsetWidth / slidesToShow;
    const newIndex = Math.round(slider.scrollLeft / slideWidth);
    setCurrentIndex(Math.min(totalSlides - 1, Math.max(0, newIndex)));
  };

  const clickCollection = (category) => {
    localStorage.setItem('selectedCategory', category);
    window.location.href = '/collection';  // Navigate to the collection page within the same tab
  }  

  return (
    <div className='colection'>
      <h1 className="colectionText">COLECTION</h1>
      <div className="slider">
        <button className="slider-button prev" onClick={prevSlide}>
          &#10094;
        </button>
        <div
          className="slider-container"
          ref={sliderRef}
          onMouseDown={handleStart}
          onMouseMove={handleMove}
          onMouseUp={handleEnd}
          onMouseLeave={handleEnd}
          onTouchStart={handleStart}
          onTouchMove={handleMove}
          onTouchEnd={handleEnd}
        >
          <div className="slider-slide">
            {obiecteArray.map((item, index) => (
              <div className="slider-image-container" key={index}>
                {item.imageUrls.map((url, idx) => (
                  <img
                    key={idx}
                    src={url}
                    alt={`Slide ${index}`}
                    className="slider-image"
                    onError={(e) => { e.target.style.display = 'none'; }}
                    onClick={() => clickCollection(item.category)}  // Updated here
                  />
                ))}
                <div className="slider-text">{item.category}</div>
              </div>
            ))}
          </div>
        </div>
        <button className="slider-button next" onClick={nextSlide}>
          &#10095;
        </button>
        <div className="slider-progress-container">
          <div className="slider-progress" ref={progressRef}></div>
        </div>
      </div>
    </div>
  );
}

export default Collection;