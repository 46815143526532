import React, { useState } from 'react';
import { backendDomain } from '../../GlobalVariables';
import './Admin.css';

function TextTermsEdit() {
    const [terms, setTerms] = useState([{ title: '', text: '' }]);

    const handleTermChange = (index, e) => {
        const { name, value } = e.target;
        const newTerms = [...terms];
        newTerms[index][name] = value;
        setTerms(newTerms);
    };

    const handleAddTerms = () => {
        setTerms([...terms, { title: '', text: '' }]);
    };

    const handleRemoveTerms = (index) => {
        const newTerms = terms.filter((_, i) => i !== index);
        setTerms(newTerms);
    };

    const handleTermsSubmit = () => {
        fetch(backendDomain+'/terms', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(terms)
        })
        .then(response => response.json())
        .then(data => console.log(data))
        .catch(error => console.error('Error:', error));
    };

    return (
        <div className="text-edit-section">
            <h2>Terms Text Edit</h2>
            {terms.map((term, index) => (
                <div key={index} className="input-group">
                    <input
                        type="text"
                        name="title"
                        value={term.title}
                        onChange={(e) => handleTermChange(index, e)}
                        placeholder="Title"
                    />
                    <input
                        type="text"
                        name="text"
                        value={term.text}
                        onChange={(e) => handleTermChange(index, e)}
                        placeholder="Text"
                    />
                    <button type="button" onClick={handleAddTerms}>+</button>
                    {terms.length > 1 && (
                        <button type="button" onClick={() => handleRemoveTerms(index)}>-</button>
                    )}
                </div>
            ))}
            <button onClick={handleTermsSubmit}>Save</button>
        </div>
    );
}

export default TextTermsEdit;
