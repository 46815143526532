import React, { useState, useEffect } from 'react';
import TextContactEdit from './TextContactEdit';
import TextTermsEdit from './TextTermsEdit';
import TextCurrencyEdit from './TextCurrencyEdit';
import TextShippingEdit from './TextShippingEdit';
import TextCountryEdit from './TextCountryEdit';
import './Admin.css';

function TextEdit({ setView }) {
    return (
        <div className="text-edit-section">
            <h2>Edit Text</h2>
            <button onClick={() => setView('showTextContactEdit')}>Text for Contact</button>
            <button onClick={() => setView('showTextTermsEdit')}>Text for Terms</button>
            <button onClick={() => setView('showTextCurrencyEdit')}>Text for Currency</button>
            <button onClick={() => setView('showTextShippingEdit')}>Text for Shipping</button>
            <button onClick={() => setView('showTextCountryEdit')}>Text for Country</button>
        </div>
    );
}

export default TextEdit;
