import React, { useState } from 'react';
import { backendDomain } from '../../GlobalVariables';
import './Admin.css';

function ImagesProductsEdit({ uniqueProducts }) {
    const [selectedProduct, setSelectedProduct] = useState('');
    const [productImages, setProductImages] = useState([]);

    const handleImageChange = (e, index) => {
        const files = Array.from(e.target.files);

        const imagePreviews = files.map((file) => ({
            file,
            preview: URL.createObjectURL(file),
        }));

        setProductImages((prevImages) => {
            const newImages = [...prevImages];
            newImages[index] = imagePreviews;
            return newImages;
        });
    };

    const handleProductImagesUpload = () => {
        if (productImages.length === 0 || !selectedProduct) {
            alert("Please select a product and at least one image.");
            return;
        }

        const formData = new FormData();
        formData.append('selectedProduct', selectedProduct);

        productImages.forEach((images) => {
            images.forEach((image) => {
                formData.append('images[]', image.file);
            });
        });

        fetch(backendDomain+'/upload-product-images', {
            method: 'POST',
            body: formData,
        })
        .then(response => response.json())
        .then(data => {
            console.log('Product images uploaded:', data);
            alert('Images uploaded successfully!');
            setProductImages([]);
        })
        .catch(error => console.error('Error:', error));
    };

    const addImageInput = () => {
        setProductImages((prevImages) => [...prevImages, []]);
    };

    const removeImageInput = (index) => {
        setProductImages((prevImages) => prevImages.filter((_, i) => i !== index));
    };

    return (
        <div className="products-images-edit">
            <h2>Products Images</h2>
            <select value={selectedProduct} onChange={(e) => setSelectedProduct(e.target.value)}>
                <option value="" disabled>Select a product</option>
                {uniqueProducts.map((product, index) => (
                    <option key={index} value={`${product.name}-${product.color}`}>
                        {product.name} - {product.color}
                    </option>
                ))}
            </select>

            {selectedProduct && (
                <div className="image-upload-section">
                    <h3>Upload Images for {selectedProduct}</h3>

                    {productImages.map((images, index) => (
                        <div key={index} className="image-input-group">
                            <input
                                type="file"
                                multiple
                                onChange={(e) => handleImageChange(e, index)}
                            />

                            <div className="image-previews">
                                {images.map((image, idx) => (
                                    <div key={idx} className="image-preview">
                                        <img src={image.preview} alt={`Preview ${idx}`} />
                                    </div>
                                ))}
                            </div>

                            <button type="button" onClick={() => removeImageInput(index)}>
                                Remove
                            </button>
                        </div>
                    ))}

                    <button type="button" onClick={addImageInput}>
                        Add More Images
                    </button>

                    <button onClick={handleProductImagesUpload}>Upload Images</button>
                </div>
            )}
        </div>
    );
}

export default ImagesProductsEdit;
