import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './Products2.css';
import { loadProducts, getProducts, backendDomain } from '../../../GlobalVariables.js';

function Products() {
    const [filters, setFilters] = useState([]);
    const [filteredItems, setFilteredItems] = useState([]);
    const [products, setProducts] = useState([]);
    const [currency, setCurrency] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        fetch(backendDomain + '/currencyGet')
            .then(response => response.json())
            .then(data => {
                setCurrency(data);
                setLoading(false);
            })
            .catch(err => {
                setError(err.message);
                setLoading(false);
            });
    }, []);

    useEffect(() => {
        async function initializeApp() {
            await loadProducts();
            const productsData = getProducts();
            setProducts(productsData);

            const savedFilters = JSON.parse(localStorage.getItem('filters')) || [];
            const selectedCategory = localStorage.getItem('selectedCategory');

            // Add selectedCategory to filters
            if (selectedCategory) {
                savedFilters.push({ type: 'Category', value: selectedCategory });
            }

            setFilters(savedFilters);
            applyFiltersAndSort(savedFilters, productsData);
        }
        initializeApp();
    }, []);

    const applyFiltersAndSort = (filters, productsData) => {
        const combinedFilters = filters.reduce((acc, filter) => {
            if (!acc[filter.type]) acc[filter.type] = [];
            acc[filter.type].push(filter.value);
            return acc;
        }, {});

        const itemsAfterFiltering = productsData.filter(item => {
            return Object.keys(combinedFilters).every(filterType => {
                if (filterType === 'Size') return combinedFilters.Size.includes(item.size);
                if (filterType === 'Color') return combinedFilters.Color.includes(item.color);
                if (filterType === 'Category') return combinedFilters.Category.includes(item.category);
                return true;
            });
        });

        const sortedItems = itemsAfterFiltering.slice().sort((a, b) => {
            const sortFilter = filters.find(filter => filter.type === 'Sort');
            if (sortFilter) {
                if (sortFilter.value === 'Price, Low to High') return a.price - b.price;
                if (sortFilter.value === 'Price, High to Low') return b.price - a.price;
                if (sortFilter.value === 'Alphabetically, A-Z') return a.name.localeCompare(b.name);
                if (sortFilter.value === 'Alphabetically, Z-A') return b.name.localeCompare(a.name);
            }
            return 0;
        });

        setFilteredItems(sortedItems);
    };

    const generateImageUrl = async (name, color, index) => {
        const url = `${backendDomain}/img/products/${name}-${color}/${index}.png`;
        try {
            const response = await fetch(url, { method: 'HEAD' });
            if (response.ok) {
                return url;
            }
        } catch (error) {
            console.error(`Failed to load image at ${url}:`, error);
        }
        return null;
    };

    const loadImageUrls = async (products) => {
        const updatedProducts = await Promise.all(products.map(async (item) => {
            const imageUrl = await generateImageUrl(item.name, item.color, 0);
            const hoverImageUrl = await generateImageUrl(item.name, item.color, 1);
            return {
                ...item,
                image: imageUrl,
                hoverImage: hoverImageUrl,
            };
        }));
        setFilteredItems(updatedProducts);
    };

    useEffect(() => {
        if (filteredItems.length > 0) {
            loadImageUrls(filteredItems);
        }
    }, [filteredItems]);

    if (loading) return <div>Loading...</div>;
    if (error) return <div>Error: {error}</div>;

    return (
        <div className='products-container'>
            <div className='items3'>
                {filteredItems.map((item, index) => (
                    <div key={index} className='product-item'>
                        <Link to={`/${item.name.toLowerCase()}-${item.category.toLowerCase()}-${item.size.toLowerCase()}-${item.color.toLowerCase()}`}>
                            <div className='product-image-wrapper'>
                                {item.image ? (
                                    <img src={item.image} alt={item.name} className='product-image default' />
                                ) : (
                                    <div className='no-image'>No Image Available</div>
                                )}
                                {item.hoverImage ? (
                                    <img src={item.hoverImage} alt={`${item.name} hover`} className='product-image hover' />
                                ) : null}
                            </div>
                            <div className='product-details'>
                                <h3 className='hj'>{item.name} {item.category}</h3>
                                <p className='pip'>Price: {item.price.toFixed(2)} {currency}</p>
                                <p className='pip'>Color: {item.color}</p>
                            </div>
                        </Link>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default Products;
