import React, { useState } from 'react';
import { backendDomain } from '../../GlobalVariables';
import './Admin.css';

function TextContactEdit() {
    const [email, setEmail] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');

    const handleEmailChange = (e) => setEmail(e.target.value);
    const handlePhoneNumberChange = (e) => setPhoneNumber(e.target.value);

    const handleContactSubmit = () => {
        const arrayData = [email, phoneNumber];

        fetch(backendDomain+'/contact', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(arrayData)
        })
        .then(response => response.json())
        .then(data => console.log(data))
        .catch(error => console.error('Error:', error));
    };

    return (
        <div className="text-edit-section">
            <h2>Contact Text Edit</h2>
            <input
                type="text"
                value={email}
                onChange={handleEmailChange}
                placeholder="Email"
            />
            <input
                type="text"
                value={phoneNumber}
                onChange={handlePhoneNumberChange}
                placeholder="Phone number"
            />
            <button onClick={handleContactSubmit}>Save</button>
        </div>
    );
}

export default TextContactEdit;
