import './Home.css';
import React, { useState, useEffect } from 'react';
import { backendDomain } from '../../GlobalVariables';

function Presentation() {
  const [imageUrlHome, setImageUrlHome] = useState(null);
  const [imageUrlLogo, setImageUrlLogo] = useState(null);

  useEffect(() => {
    const formats = ['png'];
    const basePathHomeBackground = backendDomain+'/img/backgroundHomePage/background';
    const basePathLogo = backendDomain+'/img/logo/logo';
    
    const loadImage = async () => {
      let homeLoaded = false;
      let logoLoaded = false;

      for (let format of formats) {
        const urlHome = `${basePathHomeBackground}.${format}`;
        const urlLogo = `${basePathLogo}.${format}`;
        try {
          if (!homeLoaded) {
            const responseHome = await fetch(urlHome, { method: 'HEAD' });
            if (responseHome.ok) {
              setImageUrlHome(urlHome);
              homeLoaded = true;
            }
          }

          if (!logoLoaded) {
            const responseLogo = await fetch(urlLogo, { method: 'HEAD' });
            if (responseLogo.ok) {
              setImageUrlLogo(urlLogo);
              logoLoaded = true;
            }
          }

          if (homeLoaded && logoLoaded) break;
        } catch (error) {
          console.error(`Failed to load image: ${format}`, error);
        }
      }
    };

    loadImage();
  }, []);

  return (
    <div className="presentation">
      {imageUrlHome && <img src={imageUrlHome} alt="Background" />}
      <div className='title1'>
        {imageUrlLogo && <img src={imageUrlLogo} alt="Logo" />}
      </div>
      <a className='view-colection-button' href='/collection'>SHOP NOW</a>
      <div className='space' />
    </div>
  );
}

export default Presentation;
