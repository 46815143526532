import React, { useState, useEffect } from 'react';
import { backendDomain } from '../GlobalVariables.js';
import './ActionBar1.css';
import Cart from './Cart/cart.js';
import { items } from './Cart/cart.js';
import Search from './Search/search.js';
import Detail from './Details/Details.js';

const searchDark = require('../img/icons/search_dark.png');
const shoppingBagDark = require('../img/icons/shopping_bag_dark.png');

function ActionBar() {
  const [bgImageButton1, setBgImageButton1] = useState('');
  const [bgImageButton2, setBgImageButton2] = useState('');
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 1023);
  const [cartVisible, setCartVisible] = useState(false);
  const [searchVisible, setSearchVisible] = useState(false);
  const [detailsVisible, setDetailsVisible] = useState(false);
  const [imageUrlLogo, setImageUrlLogo] = useState(null);

  const clickHandlers = [
    () => { 
      setCartVisible(prev => !prev);
    },
    () => {       
      setSearchVisible(prev => !prev);
    },
    () => {       
      setDetailsVisible(prev => !prev);
    },
  ];

  const updateImagesBasedOnScreenSize = () => {
    const screenWidth = window.innerWidth;
    setIsMobile(screenWidth <= 1023);

    if (screenWidth <= 1023) {
      setBgImageButton1(`url(${searchDark})`);
      setBgImageButton2(`url(${shoppingBagDark})`);
    }
    else {
      setBgImageButton1('');
      setBgImageButton2('');
    }
  };

  useEffect(() => {
    const loadLogoImage = async () => {
      const formats = ['png'];
      const basePathLogo = backendDomain+'/img/logo/logo';

      for (let format of formats) {
        const urlLogo = `${basePathLogo}.${format}`;
        try {
          const responseLogo = await fetch(urlLogo, { method: 'HEAD' });
          if (responseLogo.ok) {
            setImageUrlLogo(urlLogo);
            break;
          }
        } catch (error) {
          console.error(`Failed to load logo image: ${urlLogo}`, error);
        }
      }
    };

    const handleScroll = () => {
      const scrollTop = window.scrollY;
      const screenWidth = window.innerWidth;
      if (scrollTop > 50) {
        if (screenWidth <= 1023) {
          setBgImageButton1(`url(${searchDark})`);
          setBgImageButton2(`url(${shoppingBagDark})`);
        }
      } 
      else {
        updateImagesBasedOnScreenSize();
      }
    };

    const handleResize = () => {
      updateImagesBasedOnScreenSize();
    };

    loadLogoImage();
    updateImagesBasedOnScreenSize();
    window.addEventListener('scroll', handleScroll);
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    document.documentElement.style.setProperty('--buttonsColor', "black");
  }, []);

  return (
    <div>
      <div className='action-bar'>
        <div className='bar' onClick={clickHandlers[2]} >
          <div className="bars" style={{ backgroundColor: "black" }}></div>
        </div>
        <a className="title" href='/home'>
          {imageUrlLogo ? <img src={imageUrlLogo} alt="Logo" /> : null}
        </a>
        <div className="button-container">
          <div
            className="button1"
            onClick={clickHandlers[1]}
            style={{
              backgroundImage: bgImageButton1,
              backgroundSize: 'cover',
              backgroundPosition: 'center'
            }}
          >
            SEARCH
          </div>
          <div
            className="button2"
            data-content={items > 0 ? items : ''}
            onClick={clickHandlers[0]}
            style={{
              backgroundImage: bgImageButton2,
              backgroundSize: 'cover',
              backgroundPosition: 'center'
            }}
          >
            {isMobile ? (items > 0 ? items : '') : (items > 0 ? `CART (${items})` : 'CART')}
          </div>
        </div>
      </div>
      <Cart isVisible={cartVisible} onVisibilityChange={setCartVisible} />
      <Search isVisible={searchVisible} onVisibilityChange={setSearchVisible} setCartVisible={setCartVisible} />
      <Detail isVisible={detailsVisible} onVisibilityChange={setDetailsVisible} setCartVisible={setCartVisible} />
    </div>
  );
}

export default ActionBar;
