import React, { useState, useEffect } from 'react';
import ActionBar from '../../actionBar/ActionBar1.js';
import Details from '../../detail/Detailssadas.js';
import './Checkout.css';
import { getCartItems, backendDomain } from '../../GlobalVariables.js';
import { loadStripe } from '@stripe/stripe-js';

function Checkout() {
    const cartItems = getCartItems();
    
    const currentDate = new Date();
    const hours = currentDate.getHours();
    const day = currentDate.getDate();
    const month = currentDate.getMonth() + 1;
    const year = currentDate.getFullYear();
    
    const [countries, setCountries] = useState([]);
    const [shipping1, setShipping] = useState([]);
    const [currency, setcurrency] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        fetch(backendDomain+'/currencyGet')
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                setcurrency(data);
                setLoading(false);
            })
            .catch(err => {
                setError(err.message);
                setLoading(false);
            });
    }, []);
    useEffect(() => {
        fetch(backendDomain+'/shippingGet')
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                setShipping(data);
                setLoading(false);
            })
            .catch(err => {
                setError(err.message);
                setLoading(false);
            });
    }, []);
    useEffect(() => {
        fetch(backendDomain+'/countriesGet')
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                setCountries(data);
                setLoading(false);
            })
            .catch(err => {
                setError(err.message);
                setLoading(false);
            });
    }, []);
    
    const shipping = parseInt(shipping1)

    const SubTotal = () => {
        return cartItems.reduce((total, item) => total + item.price*item.quantity, 0);
    };

    const [selectedOption, setSelectedOption] = useState('');
    const [inputs, setInputs] = useState({
        email: '',
        firstName: '',
        lastName: '',
        address: '',
        city: '',
        postalCode: '',
        phone: ''
    });
    const [touched, setTouched] = useState({
        email: false,
        firstName: false,
        lastName: false,
        address: false,
        city: false,
        postalCode: false,
        phone: false
    });

    const handleChange = (event) => {
        const { name, value } = event.target;
        setInputs({
            ...inputs,
            [name]: value
        });
    };

    const handleBlur = (event) => {
        const { name } = event.target;
        setTouched({
            ...touched,
            [name]: true
        });
    };

    const validate = (name) => {
        return inputs[name] === '';
    };

    const isValidEmail = (email) => {
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailPattern.test(email);
    };
    const isValidPhoneNumber = (phone) => {
        const phonePattern = /^\d{7,15}$/;
        return phonePattern.test(phone);
    };

    const allFieldsValid = () => {
        const requiredFields = ['email', 'firstName', 'lastName', 'address', 'city', 'postalCode', 'phone'];
        for (let field of requiredFields) {
            if (validate(field)) {
                return false;
            }
        }
        if (selectedOption === '') {
            return false;
        }
        return true;
    };

    const sendDataToBackend = async () => {
        try {
            const stripe = await loadStripe("pk_test_51PhXXdJcnrPDtf86zwkeiMRVBnEzdlUWFwLRY2GLu1MlxSqHhO8yysUE2RhB4mLcuj4dYo0nYWJOAasM8EK8wpqu00gsnFtbxC");
    
            const customerData = {
                email: inputs.email,
                firstName: inputs.firstName,
                lastName: inputs.lastName,
                address: inputs.address,
                city: inputs.city,
                country: selectedOption,
                postalCode: inputs.postalCode,
                phone: inputs.phone,
                products_total: SubTotal() + shipping,
                data: `${hours}/${day}/${month}/${year}`
            };
    
            // Prepare the request body
            const body = {
                customerData,
                cartItems
            };
    
            // Send the request to the backend
            const response = await fetch(backendDomain + '/create-checkout-session', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(body)
            });
    
            // Handle the response
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
    
            const session = await response.json();
    
            // Redirect to Stripe checkout
            const result = await stripe.redirectToCheckout({
                sessionId: session.id
            });
    
            if (result.error) {
                console.error(result.error.message);
            }
        } catch (error) {
            console.error('Error:', error.message);
        }
    };
    
    cartItems.map((item, index) => (
        console.log(item.image)
    ))
    return (
        <div className='fullt'>
            <ActionBar />
            <div className='checkout'>
                <div className='productBar'>
                    {cartItems.map((item, index) => (
                        <div className='itemCheck' key={index}>
                            <img className='itemImg' src={item.image} alt="Item" />
                            <div className='itemDetails'>
                                <h3 className='itemName'>{item.name}</h3>
                                <p className='itemColor'>{item.category} | {item.color} | {item.size}</p>
                                <p className='itemQuantity'>{item.quantity}</p>
                                <p className='itemPrice'>{item.price.toFixed(2)} {currency}</p>
                            </div>
                        </div>
                    ))}
                    <div className='total'>
                        <div className='subtotal'>
                            <p>SubTotal</p>
                            <p>{SubTotal().toFixed(2)} {currency}</p>    
                        </div>
                        <div className='shipping'>
                            <p>Shipping</p>
                            <p>{shipping} {currency}</p>    
                        </div>
                        <div className='total2'>
                            <p>Total</p>
                            <p>{(SubTotal() + shipping).toFixed(2)} {currency}</p>    
                        </div>
                    </div>
                </div>
                <div className='checkoutPay'>
                    <div className='contact'>
                        <label>CONTACT</label>
                        <input
                            type='email'
                            placeholder='Email'
                            name='email'
                            id='contact'
                            value={inputs.email}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            className={(validate('email') && touched.email) || !isValidEmail(inputs.email) ? 'input-error' : ''}
                        />
                        {(!isValidEmail(inputs.email) || validate('email')) && touched.email && <span className='error'>Please enter a valid email.</span>}
                    </div>
                    <div className='delivery'>
                        <label>DELIVERY</label>
                        <select
                            value={selectedOption}
                            onChange={(e) => setSelectedOption(e.target.value)}
                            onBlur={() => setTouched({ ...touched, country: true })}
                            className={selectedOption === '' && touched.country ? 'input-error' : ''}
                        >
                            <option value="" disabled>Select an option</option>
                            {countries.map((country, index) => (
                                <option key={index} value={country}>
                                    {country}
                                </option>
                            ))}
                        </select>
                        {selectedOption === '' && touched.country && <span className='error'>Please select an option.</span>}
                        <input
                            type='text'
                            placeholder='First name'
                            name='firstName'
                            value={inputs.firstName}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            className={validate('firstName') && touched.firstName ? 'input-error' : ''}
                        />
                        {validate('firstName') && touched.firstName && <span className='error'>Please enter your first name.</span>}
                        <input
                            type='text'
                            placeholder='Last name'
                            name='lastName'
                            value={inputs.lastName}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            className={validate('lastName') && touched.lastName ? 'input-error' : ''}
                        />
                        {validate('lastName') && touched.lastName && <span className='error'>Please enter your last name.</span>}
                        <input
                            type='text'
                            placeholder='Address'
                            name='address'
                            value={inputs.address}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            className={validate('address') && touched.address ? 'input-error' : ''}
                        />
                        {validate('address') && touched.address && <span className='error'>Please enter your address.</span>}
                        <input
                            type='text'
                            placeholder='City'
                            name='city'
                            value={inputs.city}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            className={validate('city') && touched.city ? 'input-error' : ''}
                        />
                        {validate('city') && touched.city && <span className='error'>Please enter your city.</span>}
                        <input
                            type='number'
                            placeholder='Postal code'
                            name='postalCode'
                            value={inputs.postalCode}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            className={validate('postalCode') && touched.postalCode ? 'input-error' : ''}
                        />
                        {validate('postalCode') && touched.postalCode && <span className='error'>Please enter your postal code.</span>}
                        <input
                            type='number'
                            placeholder='Phone'
                            name='phone'
                            value={inputs.phone}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            className={validate('phone') && touched.phone ? 'input-error' : ''}
                        />
                        {(!isValidPhoneNumber(inputs.phone) || validate('phone')) && touched.phone && <span className='error'>Please enter a valid phone number.</span>}
                        <label>Shipping method</label>
                        <div className="shipping1">
                            <div className="left">Shipping</div>
                            <div className="right">{shipping} {currency}</div>
                        </div>
                        <button
                            onClick={sendDataToBackend}
                            disabled={cartItems.length === 0 || !allFieldsValid() || !isValidEmail(inputs.email) || !isValidPhoneNumber(inputs.phone)}
                        >
                            Pay now
                        </button>
                    </div>   
                </div>
            </div>
            <Details />
        </div>
    );
}

export default Checkout;
