import React, { useState } from 'react';
import { backendDomain } from '../../GlobalVariables';
import './Admin.css';

function ImagesHomeEdit() {
    const [uploadedImage, setUploadedImage] = useState(null);

    const handleImageHomeSubmit = () => {
        const formData = new FormData();
        formData.append('image', uploadedImage);

        fetch(backendDomain+'/upload-image-home-background', {
            method: 'POST',
            body: formData
        })
        .then(response => response.json())
        .then(data => {
            console.log('Image uploaded:', data);
            alert('Home background image uploaded successfully!');
        })
        .catch(error => console.error('Error:', error));
    };

    return (
        <div className="products-images-edit">
            <h2>Home Background Image</h2>
            <input type="file" onChange={e => setUploadedImage(e.target.files[0])} />
            <button onClick={handleImageHomeSubmit}>Upload Image</button>
        </div>
    );
}

export default ImagesHomeEdit;
