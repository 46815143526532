import React, { useState, useEffect } from 'react';
import './cart.css';
import { checkoutText, getCartItems, setCartItems, backendDomain } from '../../GlobalVariables';

export let items = getCartItems().reduce((total, item) => total + item.quantity, 0);

function Cart({ isVisible, onVisibilityChange }) {
  const [cartItems, setCartItemsState] = useState([]);
  const [currency, setCurrency] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  
  useEffect(() => {
    fetch(backendDomain+'/currencyGet')
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => {
        setCurrency(data);
        setLoading(false);
      })
      .catch(err => {
        setError(err.message);
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    const storedItems = getCartItems();
    setCartItemsState(storedItems);
    updateItemsCount(storedItems);

    storedItems.forEach(async (item, index) => {
      const imageUrl = await fetchItemImage(item.name, item.color);
      
      setCartItemsState(prevItems => {
        const newItems = [...prevItems];
        newItems[index].image = imageUrl;
        return newItems;
      });
    });
  }, []);

  const fetchItemImage = async (name, color) => {
    const url = backendDomain+`/img/products/${name.toLowerCase()}-${color.toLowerCase()}/0.png`;
      try {
        const response = await fetch(url, { method: 'HEAD' });
        if (response.ok) {
          return url;
        }
      } catch (error) {
        console.error(`Failed to load image at ${url}:`, error);
      }
    return null;
  };

  const updateItemsCount = (newItems) => {
    items = newItems.reduce((total, item) => total + item.quantity, 0);
  };

  const handleQuantityChange = (index, delta) => {
    setCartItemsState(prevItems => {
      const newItems = [...prevItems];
      const currentItem = newItems[index];
      const newQuantity = Math.max(currentItem.quantity + delta, 0);

      if (newQuantity > currentItem.quantityMax) {
        return prevItems;
      }

      if (newQuantity === 0) {
        newItems.splice(index, 1);
      } else {
        newItems[index].quantity = newQuantity;
      }

      setCartItems(newItems);
      updateItemsCount(newItems);
      console.log(currentItem.quantityMax);
      return newItems;
    });
  };

  const handleInputChange = (index, event) => {
    const value = event.target.value;
    if (/^\d*$/.test(value)) {
      setCartItemsState(prevItems => {
        const newItems = [...prevItems];
        const currentItem = newItems[index];
        newItems[index].quantity = Number(value);

        if (newItems[index].quantity > currentItem.quantityMax) {
          newItems[index].quantity = currentItem.quantityMax; // Cap quantity at max
        }
        
        if (newItems[index].quantity === 0) {
          newItems.splice(index, 1);
        }

        setCartItems(newItems);
        updateItemsCount(newItems);
        window.location.reload()
        return newItems;
      });
    }
  };

  const SubTotal = () => {
    return cartItems.reduce((total, item) => total + item.quantity * item.price, 0);
  };

  const handleRemoveItem = (index) => {
    setCartItemsState(prevItems => {
      const newItems = prevItems.filter((_, i) => i !== index);
      setCartItems(newItems);
      updateItemsCount(newItems);
      return newItems;
    });
  };

  return (
    <>
      {isVisible && cartItems.length > 0 && (
        <div className="cart-background">
          <div className="cartPartBackground">
            <button className="back" onClick={() => { onVisibilityChange(false); window.location.reload(); }}>
              &#10094; CONTINUE SHOPPING
            </button>
            <div className='bar0'></div>
            <div className='itemsGrid'>
              {cartItems.map((item, index) => (
                <div className='items' key={index}>
                  <img className='ItemImg' src={item.image} alt="Item" />
                  <div className='ItemName'>
                    <a href={`/${item.name.toLowerCase()}-${item.category.toLowerCase()}-${item.size.toLowerCase()}-${item.color.toLowerCase()}`} key={index} alt=''>
                      <h1 className='a'>{item.name} | {item.category}</h1>
                      <h1>{item.price.toFixed(2)} {currency}</h1>
                      <div className='b' />
                      <p>Color: {item.color}</p>
                      <p>Size: {item.size}</p>
                    </a>
                    <div className='count'>
                      <button onClick={() => handleQuantityChange(index, -1)} disabled={item.quantity <= 1}>-</button>
                      <input
                        value={item.quantity}
                        onChange={(event) => handleInputChange(index, event)}
                        type="text"
                      />
                      <button
                        onClick={() => handleQuantityChange(index, +1)}
                        disabled={item.quantity >= item.quantityMax}
                      >
                        +
                      </button>
                    </div>
                    <button className='x' onClick={() => handleRemoveItem(index)}>&#10005;</button>
                  </div>
                </div>
              ))}
            </div>
            <div className='pay'>
              <div className='text'>
                <h2>Subtotal</h2>
                <h2>{SubTotal().toFixed(2)} {currency}</h2>
              </div>
              <p>{checkoutText}</p>
              <button onClick={() => window.location.href = '/checkout'}>CHECK OUT</button>
            </div>
          </div>
        </div>
      )}
      {isVisible && cartItems.length === 0 && (
        <div className="cart-background">
          <div className="cartPartBackground">
            <div className='cartPartBackground0'>
              <p className="alert0">YOUR CART IS EMPTY</p>
              <button className="back0" onClick={() => { onVisibilityChange(false); window.location.reload(); }}>
                &#10094; CONTINUE SHOPPING
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Cart;
