import React, { useState, useEffect } from 'react';
import './Filter.css';
import { loadProducts, getProducts } from '../../../GlobalVariables.js';
import FilterAndSort from './FilterAndSort.js';

function Filter() {
    const [isFilterVisible, setIsFilterVisible] = useState(false);
    const [selectedCategory, setSelectedCategory] = useState('All');
    const [products, setProducts] = useState([]);
    useEffect(() => {
      async function initializeApp() {
        await loadProducts();
        const productsData = getProducts();
        setProducts(productsData);
      }
      initializeApp();
    }, []);

    useEffect(() => {
        const savedCategory = localStorage.getItem('selectedCategory') || 'All';
        setSelectedCategory(savedCategory);
    }, []);
    const AllCategory = [...new Set(products.map(item => item.category))];
    const toggleFilterVisibility = () => {
        setIsFilterVisible(!isFilterVisible);
    };

    const handleCategoryClick = (category) => {
        setSelectedCategory(category);
        localStorage.setItem('selectedCategory', category);

        const existingFilters = JSON.parse(localStorage.getItem('filters')) || [];
        const updatedFilters = existingFilters.filter(filter => filter.type !== 'Category');
        if (category !== 'All') {
            updatedFilters.push({ type: 'Category', value: category });
        }
        localStorage.setItem('filters', JSON.stringify(updatedFilters));
        window.location.reload();
    };

    return (
        <div className='filter'>
            <div className='filter2'>
                <div className='scrollA'>
                    <button 
                        className={`${selectedCategory === 'All' ? 'but2' : 'but'}`} 
                        onClick={() => handleCategoryClick('All')}
                    >
                        All
                    </button>
                    {AllCategory.map((category, index) => (
                        <button 
                            className={`${selectedCategory === category ? 'but2' : 'but'}`} 
                            key={index} 
                            onClick={() => handleCategoryClick(category)}
                        >
                            {category}
                        </button>
                    ))}
                </div>
                <button className='fil' onClick={toggleFilterVisibility}>+ Filter</button>
                <div className='u'></div>
            </div>
            {isFilterVisible && <FilterAndSort onClose={toggleFilterVisibility} />}
        </div>
    );
}

export default Filter;
