import React, { useState, useEffect } from 'react';
import { loadProducts, getProducts, ADMINPASSWORD } from '../../GlobalVariables';
import './Admin.css';
import TextEdit from './TextEdit';
import ImagesEdit from './ImagesEdit';
import TextContactEdit from './TextContactEdit';
import TextTermsEdit from './TextTermsEdit';
import TextCurrencyEdit from './TextCurrencyEdit';
import TextShippingEdit from './TextShippingEdit';
import TextCountryEdit from './TextCountryEdit';
import ImagesHomeEdit from './ImagesHomeEdit';
import ImagesLogoEdit from './ImagesLogoEdit';
import ImagesCollectionEdit from './ImagesCollectionEdit';
import ImagesProductsEdit from './ImagesProductsEdit';

function Admin() {
    const [password, setPassword] = useState('');
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [view, setView] = useState(null);
    const [products, setProducts] = useState([]);
    const [categories, setCategories] = useState([]);
    const [uniqueProducts, setUniqueProducts] = useState([]);

    useEffect(() => {
        async function initializeApp() {
            await loadProducts();
            const productsData = getProducts();
            setProducts(productsData);
        }
        initializeApp();
    }, []);

    useEffect(() => {
        const uniqueCategories = [...new Set(products.map(product => product.category))];
        setCategories(uniqueCategories);
    }, [products]);

    useEffect(() => {
        async function initializeApp() {
            await loadProducts();
            const productsData = getProducts();

            const simplifiedProducts = productsData.map(product => ({
                color: product.color,
                name: product.name,
            }));

            const uniqueProducts = simplifiedProducts.reduce((acc, current) => {
                const isDuplicate = acc.some(product => product.color === current.color && product.name === current.name);
                if (!isDuplicate) {
                    acc.push(current);
                }
                return acc;
            }, []);

            setProducts(productsData);
            setUniqueProducts(uniqueProducts);
        }
        initializeApp();
    }, []);

    const correctPassword = ADMINPASSWORD;

    const handlePasswordChange = (e) => {
        setPassword(e.target.value);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (password === correctPassword) {
            setIsAuthenticated(true);
        } else {
            alert('Parola este greșită.');
        }
    };

    return (
        <div className="admin-container">
            {!isAuthenticated ? (
                <div className="login-form-container">
                    <form onSubmit={handleSubmit} className="login-form">
                        <label className="form-label">
                            Parola:
                            <input
                                type="password"
                                value={password}
                                onChange={handlePasswordChange}
                                className="password-input"
                            />
                        </label>
                        <button type="submit" className="submit-button">Autentificare</button>
                    </form>
                </div>
            ) : (
                <div>
                    {view === null ? (
                        <div>
                            <button onClick={() => setView('textEdit')}>Text edit</button>
                            <button onClick={() => setView('imagesEdit')}>Images edit</button>
                        </div>
                    ) : (
                        <div>
                            {view === 'textEdit' && (
                                <TextEdit setView={setView} />
                            )}
                            {view === 'imagesEdit' && (
                                <ImagesEdit setView={setView} />
                            )}
                            {view === 'showTextContactEdit' && (
                                <TextContactEdit />
                            )}
                            {view === 'showTextTermsEdit' && (
                                <TextTermsEdit />
                            )}
                            {view === 'showTextCurrencyEdit' && (
                                <TextCurrencyEdit />
                            )}
                            {view === 'showTextShippingEdit' && (
                                <TextShippingEdit />
                            )}
                            {view === 'showTextCountryEdit' && (
                                <TextCountryEdit />
                            )}
                            {view === 'imagesHomeEdit' && (
                                <ImagesHomeEdit />
                            )}
                            {view === 'imagesLogoEdit' && (
                                <ImagesLogoEdit />
                            )}
                            {view === 'imagesColectionEdit' && (
                                <ImagesCollectionEdit categories={categories} />
                            )}
                            {view === 'imagesProductsEdit' && (
                                <ImagesProductsEdit uniqueProducts={uniqueProducts} />
                            )}

                            <button onClick={() => setView(null)}>Back</button>
                        </div>
                    )}
                </div>
            )}
        </div>
    );
}

export default Admin;
