import React, { useState } from 'react';
import { backendDomain } from '../../GlobalVariables';
import './Admin.css';

function ImagesLogoEdit() {
    const [uploadedImageLogo, setUploadedImageLogo] = useState(null);

    const handleImageLogoSubmit = () => {
        if (!uploadedImageLogo) {
            alert('Please select a logo image.');
            return;
        }

        const formData = new FormData();
        formData.append('image', uploadedImageLogo);

        fetch(backendDomain+'/upload-image-logo', {
            method: 'POST',
            body: formData
        })
        .then(response => response.json())
        .then(data => {
            console.log('Image uploaded:', data);
            alert('Logo image uploaded successfully!');
        })
        .catch(error => console.error('Error:', error));
    };

    return (
        <div className="products-images-edit">
            <h2>Logo Image</h2>
            <input
                type="file"
                onChange={e => setUploadedImageLogo(e.target.files[0])}
            />
            <button onClick={handleImageLogoSubmit}>Upload Logo Image</button>
        </div>
    );
}

export default ImagesLogoEdit;
