import React, { useEffect, useState } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { loadProducts, getProducts } from './GlobalVariables.js';
import Home from './pages/home/Home.js';
import Collection from './pages/collection/Collection.js';
import Product from './pages/product/Product1.js';
import Checkout from './pages/checkout/Checkout.js';
import Succes from './pages/checkout/succes.js';
import Cancel from './pages/checkout/cancel.js';
import Contact from './pages/details/contact/Contact1.js';
import Terms from './pages/details/Terms/Terms.js';
import Admin from './pages/admin/Admin.js'

export default function App() {
  const [products, setProducts] = useState([]);

  useEffect(() => {
    async function fetchData() {
      await loadProducts();
      const productsData = getProducts();
      setProducts(productsData);
    }
    fetchData();
  }, []);

  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route index element={<Home />} />
          <Route path="/home" element={<Home />} />
          <Route path="/view" element={<Home />} />
          <Route path="/collection" element={<Collection />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="/efhvawsehdfhaikcfkasdhdfklawkdflbcvjkasAxALPDOPQWHFJKLASLPCLPASJDKLPASJKLPDJASKLJDFLAHWKDILAJDCKLASJKLJDOPAKLDJKLAWSJDOPVBZSDJKLFJOPAWKCKAZSKLCAOPCKsajklDFJOPPQAWDFCHASKLFOAPAWSFDHNBASLIDPKSZlPJFPLKASLP" element={<Admin />} />
          {products.map((item, index) => {
            const path = `/${item.name.toLowerCase()}-${item.category.toLowerCase()}-${item.size.toLowerCase()}-${item.color.toLowerCase()}`;
            return (
              <Route
                key={item.name + item.category + index}
                path={path}
                element={<Product name={item.name} category={item.category} />}
              />
            );
          })}
          <Route path="/checkout" element={<Checkout />} />
          <Route path="/success" element={<Succes />} />
          <Route path="/cancel" element={<Cancel />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}
