import React from 'react';
import './Details.css';
import { LinksPromo } from '../../GlobalVariables.js';

function Detail({ isVisible, onVisibilityChange, setCartVisible }) {
    const handleButtonClick = () => {
        setCartVisible(false);
        onVisibilityChange(false);
    };

    return (
        <div className='detailsBackground' style={{ display: isVisible ? 'block' : 'none' }}>
            <div className="detailsi">
                <button className='r' onClick={handleButtonClick}>&#10005;</button>
                <a className='a' href='/home'>HOME</a>
                <a className='a' href='/collection'>COLECTION</a>
                <a className='a' href='/contact'>CONTACT</a>
                <a className='a' href='/terms'>TERMS & CONDITIONS</a>
                <div className='links'>
                    {LinksPromo.map((item, index) => (
                        <a key={index} href={item.link} target="_blank" rel="noopener noreferrer">
                            {item.name}
                        </a>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default Detail;
