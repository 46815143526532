import React, { useState, useEffect } from 'react';
import './FilterAndSort.css';
import { loadProducts, getProducts } from '../../../GlobalVariables.js';

function FilterAndSort({ onClose }) {
    const [selectedSize, setSelectedSize] = useState(null);
    const [selectedColor, setSelectedColor] = useState(null);
    const [selectedSort, setSelectedSort] = useState(null);
    const [filters, setFilters] = useState([]);
    const [sizes, setSizes] = useState([]);
    const [colors, setColors] = useState([]);
    const [sortOptions] = useState([
        "Price, Low to High",
        "Price, High to Low",
        "Alphabetically, A-Z",
        "Alphabetically, Z-A"
    ]);

    useEffect(() => {
        async function fetchData() {
            await loadProducts();
            const productsData = getProducts();
            const uniqueSizes = [...new Set(productsData.map(item => item.size))];
            const uniqueColors = [...new Set(productsData.map(item => item.color))];
            setSizes(uniqueSizes);
            setColors(uniqueColors);
        }
        fetchData();

        const savedFilters = JSON.parse(localStorage.getItem('filters')) || [];
        setFilters(savedFilters);

        const savedSize = localStorage.getItem('selectedSize');
        if (savedSize) setSelectedSize(savedSize);

        const savedColor = localStorage.getItem('selectedColor');
        if (savedColor) setSelectedColor(savedColor);

        const savedSort = localStorage.getItem('selectedSort');
        if (savedSort) setSelectedSort(savedSort);
    }, []);

    useEffect(() => {
        // Salvează filtrele și selecțiile în localStorage
        localStorage.setItem('filters', JSON.stringify(filters));
        if (selectedSize) {
            localStorage.setItem('selectedSize', selectedSize);
        } else {
            localStorage.removeItem('selectedSize');
        }

        if (selectedColor) {
            localStorage.setItem('selectedColor', selectedColor);
        } else {
            localStorage.removeItem('selectedColor');
        }

        if (selectedSort) {
            localStorage.setItem('selectedSort', selectedSort);
        } else {
            localStorage.removeItem('selectedSort');
        }
    }, [filters, selectedSize, selectedColor, selectedSort]);

    const handleSizeClick = (size) => {
        setSelectedSize(prevSize => {
            const newSize = prevSize === size ? null : size;
            updateFilters(newSize, selectedColor, selectedSort);
            window.location.reload();
            return newSize;
        });
    };

    const handleColorClick = (color) => {
        setSelectedColor(prevColor => {
            const newColor = prevColor === color ? null : color;
            updateFilters(selectedSize, newColor, selectedSort);
            window.location.reload();
            return newColor;
        });
    };

    const handleSortClick = (sort) => {
        setSelectedSort(prevSort => {
            const newSort = prevSort === sort ? null : sort;
            updateFilters(selectedSize, selectedColor, newSort);
            window.location.reload();
            return newSort;
        });
    };

    const updateFilters = (size, color, sort) => {
        let newFilters = [];
        if (size) newFilters.push({ type: 'Size', value: size });
        if (color) newFilters.push({ type: 'Color', value: color });
        if (sort) newFilters.push({ type: 'Sort', value: sort });
        setFilters(newFilters);
    };

    return (
        <div className='Filtersbackground'>
            <div className='Filters_background'>
                <div className='tabu'>
                    <h4>FILTER AND SORT</h4>

                    <div className='tur'>
                        <h2>SIZE</h2>
                        {sizes.map((size, index) => (
                            <button
                                className={`p2${selectedSize === size ? 'selected' : ''}`}
                                key={index}
                                onClick={() => handleSizeClick(size)}
                            >
                                {size}
                            </button>
                        ))}
                    </div>
                    <div className='tur2'>
                        <h2>COLOR</h2>
                        {colors.map((color, index) => (
                            <button
                                className={`p2${selectedColor === color ? 'selected' : ''}`}
                                key={index}
                                onClick={() => handleColorClick(color)}
                            >
                                {color}
                            </button>
                        ))}
                    </div>

                    <div className='tur2'>
                        <h2>SORT BY</h2>
                        {sortOptions.map((sort, index) => (
                            <button
                                className={`p2${selectedSort === sort ? 'selected' : ''}`}
                                key={index}
                                onClick={() => handleSortClick(sort)}
                            >
                                {sort}
                            </button>
                        ))}
                    </div>

                    <button className='close' onClick={onClose}>&#10005;</button>
                </div>
            </div>
        </div>
    );
}

export default FilterAndSort;
