import ActionBar from '../../actionBar/ActionBar1.js';
import Details from '../../detail/Detailssadas.js';
import './Checkout.css';
import { frontendDomain } from '../../GlobalVariables.js';

function Cancel() {

    return (
        <div className='hjk'>
            <ActionBar/>
            <div className="payment-cancel-container">
            <div className="cancel-icon">&#10006;</div>
            <h1 className="cancel-cancel-message">Payment Canceled</h1>
            <p className="cancel-cancel-description">Your payment was canceled</p>
            <a className="continue-cancel-button" href={frontendDomain+"/collection"}>Continue Shopping</a>
            </div>
            <Details/>
        </div>
    )
}
export default Cancel