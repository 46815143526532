import ActionBar from '../../actionBar/ActionBar1.js';
import Details from '../../detail/Detailssadas.js';
import './Checkout.css';
import { frontendDomain, getCartItems, setCartItems } from '../../GlobalVariables.js';

function Succes() {

    let cartItems = getCartItems();

    cartItems = [];

    setCartItems(cartItems);
    
    return (
        <div className='hjk'>
            <ActionBar/>
            <div className="payment-success-container">
            <div className="success-icon">&#10004;</div>
            <h1 className="success-message">Payment Successful</h1>
            <p className="success-description">Thank you for your purchase!</p>
            <a className="continue-button" href={frontendDomain+"/collection"}>Continue Shopping</a>
            </div>
            <Details/>
        </div>
    )
}
export default Succes