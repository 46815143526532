import ActionBar from '../../actionBar/ActionBar';
import Presentation from './colectionPresetation';
import Collection from './colection/Slider';
import Details from '../../detail/Detailsihuyhu.js';
import './Home.css';

function Home() { 

  return (
    <div className="Home">
        <ActionBar/>
        <Presentation/>
        <Collection/>
        <Details/>
    </div>
  ); 
}

export default Home;
