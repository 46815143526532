import ActionBar from '../../../actionBar/ActionBar1.js';
import Details from '../../../detail/Detailssadas.js';
import {contact, backendDomain} from '../../../GlobalVariables.js'
import React, { useEffect, useState } from 'react';
import './Contact.css';

function Contact() {
    const [contactInfo, setContactInfo] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        // Fetch data from the /contactGet endpoint
        fetch(backendDomain+'/contactGet')
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                setContactInfo(data);
                setLoading(false);
            })
            .catch(err => {
                setError(err.message);
                setLoading(false);
            });
    }, []);
    return (
        <div className='fill'>
            <ActionBar />
            <div className='full'>
                <div className="contact-info">
                    <h1>Contact Us</h1>
                    <div className="contact-item">
                        <h2>Email</h2>
                        <p><a href={`mailto:${contactInfo[0]}`}>{contactInfo[0]}</a></p>
                    </div>
                    <div className="contact-item">
                        <h2>Phone</h2>
                        <p><a href={`mailto:${contactInfo[1]}`}>{contactInfo[1]}</a></p>
                    </div>
                    <div className="contact-item">
                        <h2>Store Address</h2>
                        <p><a href={contact}>{contact}</a></p>
                    </div>
                </div>
            </div>
            <Details />
        </div>
    );
}

export default Contact;
