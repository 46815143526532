import React, { useState } from 'react';
import { backendDomain } from '../../GlobalVariables';
import './Admin.css';

function ImagesCollectionEdit({ categories }) {
    const [collectionImages, setCollectionImages] = useState({});

    const handleImageCollectionChange = (category, file) => {
        setCollectionImages(prevImages => ({
            ...prevImages,
            [category]: file
        }));
    };

    const handleImageCollectionSubmit = () => {
        const formData = new FormData();
        formData.append('categories', JSON.stringify(categories));

        for (const [category, file] of Object.entries(collectionImages)) {
            formData.append('images[]', file);
        }

        fetch(backendDomain+'/upload-image-collection-background', {
            method: 'POST',
            body: formData
        })
        .then(response => response.json())
        .then(data => {
            console.log('Images uploaded:', data);
            alert('Images uploaded successfully!');
        })
        .catch(error => console.error('Error:', error));
    };

    return (
        <div className="products-images-edit">
            <h2>Collection Images</h2>
            {categories.map((category, index) => (
                <div key={index} className="input-group">
                    <label>{category}</label>
                    <input
                        type="file"
                        onChange={(e) => handleImageCollectionChange(category, e.target.files[0])}
                    />
                </div>
            ))}
            <button onClick={handleImageCollectionSubmit}>Upload Images</button>
        </div>
    );
}

export default ImagesCollectionEdit;
