import React, { useState } from 'react';
import { backendDomain } from '../../GlobalVariables';
import './Admin.css';

function TextCountryEdit() {
    const [countries, setCountries] = useState(['']);

    const handleCountryChange = (index, e) => {
        const newCountries = [...countries];
        newCountries[index] = e.target.value;
        setCountries(newCountries);
    };

    const handleAddCountry = () => {
        setCountries([...countries, '']);
    };

    const handleRemoveCountry = (index) => {
        const newCountries = countries.filter((_, i) => i !== index);
        setCountries(newCountries);
    };

    const handleCountrySubmit = async () => {
        try {
            const response = await fetch(backendDomain+'/countries', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(countries),
            });

            if (!response.ok) {
                const errorMessage = await response.text();
                throw new Error(errorMessage);
            }

            const data = await response.json();
            console.log(data);
        } catch (error) {
            console.error('Error:', error);
        }
    };

    return (
        <div className="text-edit-section">
            <h2>Country Text Edit</h2>
            {countries.map((country, index) => (
                <div key={index} className="input-group">
                    <input
                        type="text"
                        value={country}
                        onChange={(e) => handleCountryChange(index, e)}
                        placeholder="Country"
                    />
                    <button type="button" onClick={handleAddCountry}>+</button>
                    {countries.length > 1 && (
                        <button type="button" onClick={() => handleRemoveCountry(index)}>-</button>
                    )}
                </div>
            ))}
            <button onClick={handleCountrySubmit}>Save</button>
        </div>
    );
}

export default TextCountryEdit;
