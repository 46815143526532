import React, { useState } from 'react';
import { backendDomain } from '../../GlobalVariables';
import './Admin.css';

function TextCurrencyEdit() {
    const [currency, setCurrency] = useState('');

    const handleCurrencyChange = (e) => setCurrency(e.target.value);

    const handleCurrencySubmit = () => {
        fetch(backendDomain+'/currency', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ currency })
        })
        .then(response => response.json())
        .then(data => console.log("Received data:", data))
        .catch(error => console.error('Error:', error));
    };

    return (
        <div className="text-edit-section">
            <h2>Currency Text Edit</h2>
            <input
                type="text"
                value={currency}
                onChange={handleCurrencyChange}
                placeholder="Currency"
            />
            <button onClick={handleCurrencySubmit}>Save</button>
        </div>
    );
}

export default TextCurrencyEdit;
