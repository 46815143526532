import React from 'react';
import ImagesHomeEdit from './ImagesHomeEdit';
import ImagesLogoEdit from './ImagesLogoEdit';
import ImagesCollectionEdit from './ImagesCollectionEdit';
import ImagesProductsEdit from './ImagesProductsEdit';
import './Admin.css';

function ImagesEdit({ setView }) {
    return (
        <div className="images-edit-section">
            <h2>Edit Images</h2>
            <button onClick={() => setView('imagesProductsEdit')}>Images for products</button>
            <button onClick={() => setView('imagesColectionEdit')}>Collection Images</button>
            <button onClick={() => setView('imagesHomeEdit')}>Home Background Image</button>
            <button onClick={() => setView('imagesLogoEdit')}>Logo Image</button>
        </div>
    );
}

export default ImagesEdit;
