import React, { useState } from 'react';
import { backendDomain } from '../../GlobalVariables';
import './Admin.css';

function TextShippingEdit() {
    const [shipping, setShipping] = useState('');

    const handleShippingChange = (e) => setShipping(e.target.value);

    const handleShippingSubmit = () => {
        fetch(backendDomain+'/shipping', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ shipping })
        })
        .then(response => response.json())
        .then(data => console.log("Received data:", data))
        .catch(error => console.error('Error:', error));
    };

    return (
        <div className="text-edit-section">
            <h2>Shipping Value Edit</h2>
            <input
                type="number"
                value={shipping}
                onChange={handleShippingChange}
                placeholder="Shipping"
            />
            <button onClick={handleShippingSubmit}>Save</button>
        </div>
    );
}

export default TextShippingEdit;
